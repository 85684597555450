<template>
	<footer class="global-footer" v-if="footerData">
		<div class="global-footer__section">
			<nuxt-link to="/" class="global-footer__logo"><img width="85" height="50" v-lazy-load data-src="/img/ui/logo-bn.svg" alt="B+N Logo"/></nuxt-link>
			<EmailSignUp 
				:blok="signup" 
				v-for="signup in getSignUpContent()" :key="signup._uid" />

			<nav class="global-footer__nav">
				<ul>
					<li v-for="globalfooter_link in getLinkSet()" 
						:key="globalfooter_link._uid">
						<a v-if="globalfooter_link.external" 
							:href="globalfooter_link.link.cached_url" 
							target="_blank" rel="noopener">{{ globalfooter_link.display_name }}</a>
						<nuxt-link v-else :to="'/' + globalfooter_link.link.cached_url + '/'">{{ globalfooter_link.display_name }}</nuxt-link>
					</li>
				</ul>
			</nav>
		</div>

		<div class="global-footer__section">
			<Social
				:blok="social" v-for="social in getSocial()" :key="social._uid" />

			<p v-if="footerData.story && footerData.story.content" >
				<small>{{ this.footerData.story.content.copyright }}</small>
			</p>
		</div>
	</footer>
</template>

<style lang="scss">
.global-footer {
	background-color: var(--black);
	color: var(--white);

	&__section {
		margin-block-end: 20px;
		padding: 0 max(25px, 50vw - var(--content-width) / 2);

		ul {
			padding-inline-start: 0;

			li::before {
				display: none;
			}

			li {
				--min-font: 1.125;
				--max-font: 1.125;
			}
		}

		a {
			color: inherit;
			display: inline-block;
			text-decoration: none;
		}
	}
}

.global-footer__logo {
	display: inline-block;
}

/* Small Screen Only */
@media all and #{$mq-s-max} {
	.global-footer {
		padding: 30px 0 20px;
	}

	.global-footer__logo {
		margin-bottom: 50px;

		img {
			width: 60px;
		}
	}

	.global-footer__nav {
		margin-block-end: 35px;

		a {
			padding: 5px 0;
		}
	}
}

/* Medium Screen */
@media all and #{$mq-m-min} {
	.global-footer {
		padding: 40px 0 20px;
	}

	.global-footer__logo {
		grid-column: 1 / span 2;
		margin-bottom: 60px;

		img {
			width: 72px;
		}
	}

	.global-footer__section {
		.email-signup {
			grid-column: 1 / span 6;
			grid-row: 2;
		}

		&:first-of-type {
			@include grid;
		}
	}

	.global-footer__nav {
		grid-row: 2;
		padding-block-start: 6px;

		a {
			padding: 6px 0;
		}
	}
}

/* Medium Screen Only */
@media all and #{$mq-m-min}, #{$mq-m-max} {
	.global-footer__nav {
		grid-column: 10 / span 3;
	}
}

/* Large Screen */
@media all and #{$mq-l-min} {
	.global-footer__logo {
		margin-bottom: 70px;

		img {
			width: 85px;
		}
	}

	.global-footer__nav {
		grid-column: 11 / span 2;
	}
}

/* Hover */
@media (hover: hover) and(pointer: fine) {
	.global-footer a:hover {
		text-decoration: underline;
	}
}
</style>

<script>
export default {
	data() {
		return {}
	},
	components: {
		EmailSignUp: () => import('@/components/EmailSignUp.vue'),
		Social: () => import('@/components/Social.vue')
	},
	computed: {
		footerData() {
			return this.$store.getters['globalFooter/getFooterData']
		}
	},
	mounted() {
		this.$store.dispatch('globalFooter/fetchAllFooterData');
	},
	methods: {
		//Because v-for takes priority of v-if
		getSignUpContent() {
			if(this.footerData.story && this.footerData.story.content){
				return this.footerData.story.content.signup;
			}
			return {};
		},
		getLinkSet() {
			if(this.footerData.story && this.footerData.story.content){
				return this.footerData.story.content.linkset_1;
			}
			return {};
		},
		getSocial() {
			if(this.footerData.story && this.footerData.story.content){
				return this.footerData.story.content.social;
			}
			return {};
		},
		contactForm: function() {
			this.showNav = false
			this.$bus.$emit('openContactModal')
		}
	},
	props: ['blok']
}
</script>
