var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.footerData
    ? _c("footer", { staticClass: "global-footer" }, [
        _c(
          "div",
          { staticClass: "global-footer__section" },
          [
            _c(
              "nuxt-link",
              { staticClass: "global-footer__logo", attrs: { to: "/" } },
              [
                _c("img", {
                  directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                  attrs: {
                    width: "85",
                    height: "50",
                    "data-src": "/img/ui/logo-bn.svg",
                    alt: "B+N Logo"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.getSignUpContent(), function(signup) {
              return _c("EmailSignUp", {
                key: signup._uid,
                attrs: { blok: signup }
              })
            }),
            _vm._v(" "),
            _c("nav", { staticClass: "global-footer__nav" }, [
              _c(
                "ul",
                _vm._l(_vm.getLinkSet(), function(globalfooter_link) {
                  return _c(
                    "li",
                    { key: globalfooter_link._uid },
                    [
                      globalfooter_link.external
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: globalfooter_link.link.cached_url,
                                target: "_blank",
                                rel: "noopener"
                              }
                            },
                            [_vm._v(_vm._s(globalfooter_link.display_name))]
                          )
                        : _c(
                            "nuxt-link",
                            {
                              attrs: {
                                to:
                                  "/" + globalfooter_link.link.cached_url + "/"
                              }
                            },
                            [_vm._v(_vm._s(globalfooter_link.display_name))]
                          )
                    ],
                    1
                  )
                }),
                0
              )
            ])
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "global-footer__section" },
          [
            _vm._l(_vm.getSocial(), function(social) {
              return _c("Social", { key: social._uid, attrs: { blok: social } })
            }),
            _vm._v(" "),
            _vm.footerData.story && _vm.footerData.story.content
              ? _c("p", [
                  _c("small", [
                    _vm._v(_vm._s(this.footerData.story.content.copyright))
                  ])
                ])
              : _vm._e()
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }